import { CircularProgress, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import CoursesAPI from '../../CoursesContainer/course-api';
import Alert from '../../../components/common/Alert';

const CoursesModal = ({ open, onClose, announcement, handleOpen }) => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open && announcement) {
      (async () => {
        try {
            const { data: allCourses } = await CoursesAPI.getCourses(1, 1000);

            const { data: announcementCourses } =
            await CoursesAPI.getAllAnnouncementsByCourseId(announcement);

            const checkedCourses = allCourses.cursos.map((c) => {
            return announcementCourses.find((ac) => c.id === ac.courseId.id)
              ? { ...c, checked: true }
              : { ...c, checked: false };
            });

            checkedCourses.sort((a, b) => a.name.localeCompare(b.name));

            setCourses(checkedCourses);
        } catch (error) {
          setMessage({ type: 'error', message: 'Error al cargar los cursos.' });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open, announcement]);

  const associateCourse = async () => {
    if (selectedCourseIds.length === 0) {
      return;
    }

    try {
      await CoursesAPI.associateCoursesWithAnnouncement(
        announcement,
        selectedCourseIds
      );

      setMessage('Cursos guardados exitosamente.');
    } catch (error) {
      setMessage('Hubo un error al guardar los cursos.');
    }
  };

  const selectedCourses = (event) => {
    const courseId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    setSelectedCourseIds((prevIds) => {
      if (isChecked) {
        return [...prevIds, courseId];
      } else {
        return prevIds.filter((id) => id !== courseId);
      }
    });

    const updatedCourses = courses.map((course) => {
      if (course.id === courseId) {
        return { ...course, checked: isChecked };
      }
      return course;
    });
    setCourses(updatedCourses);
  };

  const onClickCourse = (clickedCourse) => {
    const updatedCourses = courses.map((course) => {
      if (course.id === clickedCourse.id) {
        return { ...course, checked: !course.checked };
      }
      return course;
    });

    const selectedIds = updatedCourses
      .filter((course) => course.checked)
      .map((course) => course.id);
    setSelectedCourseIds(selectedIds);
    setCourses(updatedCourses);
  };

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setMessage(null);
        onClose();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [message]);

  return (
    <>
      <Modal open={open} onClose={onClose} className="modal">
        <ModalContent title="Editar cursos" onClick={onClose}>
          {message && (
            <div className="message-container">
              <Alert type={message.includes('error') ? 'error' : 'success'}>
                {message}
              </Alert>
            </div>
          )}
          <div className="box">
            <div className="box__header">
              <span onClick={handleOpen}>Agregar curso</span>
            </div>
            <hr />
            <span>
              Selecciona los cursos correspondientes a la convocatoria
            </span>
            <div className="box__container">
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 200,
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              ) : courses.length > 0 ? (
                courses.map((course) => (
                  <label key={course.id}>
                    <input
                      type="checkbox"
                      name="curso"
                      value={course.id}
                      checked={course.checked}
                      onClick={() => onClickCourse(course)}
                      onChange={selectedCourses}
                    />
                    <span>{course.name}</span>
                  </label>
                ))
              ) : (
                <div>No hay cursos disponibles</div>
              )}
            </div>
          </div>
          <div className="modal__action-container">
            <Button secondary onClick={onClose}>
              Volver
            </Button>
            <Button
              onClick={associateCourse}
              disabled={selectedCourseIds.length === 0}
            >
              Guardar
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CoursesModal;
