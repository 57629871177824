import request from '../../utils/request';

function getCourses(pageNumber, limit) {
  const page = pageNumber ? `&pageNumber=${pageNumber}` : '';
  const limitPerPage = limit ? `?&pageSize=${limit}` : '';
  return request({
    url: `courses${limitPerPage}${page}`,
    method: 'GET',
    private: true,
  });
}
function getCourse(id) {
  return request({
    url: `course/${id}`,
    method: 'GET',
    private: true,
  });
}
function addCourse(payload) {
  return request({
    url: 'course',
    method: 'POST',
    data: payload,
    private: true,
  });
}
function updateCourse(payload) {
  return request({
    url: `course`,
    method: 'PATCH',
    private: true,
    data: payload,
  });
}

function getInstitution() {
  return request({
    url: `institutions`,
    method: 'GET',
    private: true,
  });
}

function createInstitution(institution) {
  return request({
    url: `institutions/`,
    method: 'POST',
    private: true,
    data: institution,
  });
}
function toggleCourseActivation(courseId, active) {
  return request({
    url: 'course/toggle',
    method: 'PATCH',
    private: true,
    data: {
      id: courseId,
      active,
    },
  });
}
function associateCoursesWithAnnouncement(announcementId, courseIds) {
  return request({
    url: `course/${announcementId}`,
    method: 'POST',
    private: true,
    data: {
      announcementId: announcementId,
      courses: courseIds,
    },
  });
}
function getAllAnnouncementsByCourseId(id) {
  return request({
    url: `course/announcement/${id}`,
    method: 'GET',
    private: true,
  });
}

function getAnnouncementReport(id) {
  return request({
    url: `course/announcement/${id}/report`,
    method: 'GET',
    private: true,
  });
}

function requestAnnouncementReport(id, payload) {
  return request({
    url: `course/announcement/${id}/lms-report`,
    method: 'POST',
    data: payload,

  });
}

const CoursesAPI = {
  addCourse,
  getCourses,
  getCourse,
  updateCourse,
  getInstitution,
  createInstitution,
  getAnnouncementReport,
  toggleCourseActivation,
  getAllAnnouncementsByCourseId,
  associateCoursesWithAnnouncement,
  requestAnnouncementReport,
};

export default CoursesAPI;
