import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Chip } from '@material-ui/core';
import ModalContent from '../../../components/common/Modal/ModalContent';
import Button from '../../../components/common/Button';
import CoursesAPI from '../../CoursesContainer/course-api'; 
import workshopApi from '../../WorkShopContainer/workshop-api';
import Alert from '../../../components/common/Alert';

// add validation for the email input
const EmailInput = ({ emails, setEmails }) => {
  const [inputValue, setInputValue] = useState('');
	const [error, setError] = useState(false);

  const handleKeyDown = (e) => {
	if (e.key === 'Enter' && inputValue) {
	  if (validateEmail(inputValue)) {
		setEmails([...emails, inputValue]);
		setInputValue('');
	  } else {
				setError(true);
	  }
	}
  };
	

  const handleDelete = (emailToDelete) => () => {
	setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const validateEmail = (email) => {
	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return re.test(String(email).toLowerCase());
  };

  return (
	<Box>
	  <TextField
		label="Agregar email"
		value={inputValue}
		onChange={(e) => setInputValue(e.target.value)}
		onKeyDown={handleKeyDown}
		fullWidth
	  />
			{error && !validateEmail(inputValue) && inputValue && (
				<Box mt={1} color="red">
					Por favor ingresa una dirección de correo válida.
				</Box>
			)}
	  <Box mt={2}>
		{emails.map((email, index) => (
		  <Chip
			key={index}
			label={email}
			onDelete={handleDelete(email)}
			style={{ margin: '0 4px 4px 0' }}
		  />
		))}
	  </Box>
	</Box>
  );
};

const ReportModal = ({ open, announcementId, onClose, title, mainText, hintText, action}) => {
	const [emails, setEmails] = useState([]);
	const [loading, setLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });

	const handleClose = () => {
		setAlertMessage({ type: '', message: '' });
		setEmails([]);
		onClose();
	};
	
  const requestReport = async () => {
	try {
		if (emails.length === 0) {
			setAlertMessage({ type: 'error', message: 'Por favor agrega al menos un correo.' });
			return;
		}
		setLoading(true);

		switch (action) {
			case 'report-courses':
				await CoursesAPI.requestAnnouncementReport(announcementId, {emails});
				break;
			case 'report-workshops':
				await workshopApi.requestAnnouncementReport(announcementId, {emails});
				break;
			default:
				break;
		}

			

			setLoading(false);
			setAlertMessage({ type: 'success', message: 'Se ha generado tu solicitud de reporte exitosamente. Revisa tu correo en unos minutos.' });
   
		} catch (error) {
			setLoading(false);
			setAlertMessage({ type: 'error', message: 'Ocurrió un error al solicitar el informe. Por favor, inténtalo de nuevo.' });
	}
  };

	return (
		<Modal open={open} onClose={handleClose} className="modal">
			<ModalContent
				onClick={handleClose}
				title={title}
			>
				{alertMessage.message && (
					<div className="success-message">
						<Alert type={alertMessage.type}>{alertMessage.message}</Alert>
					</div>
				)}
				<div className="report-modal">
					<span style={{ textAlign: 'justify' }}>
						{mainText}
						<br/>
						Este reporte será enviado al correo que ingreses a continuación. Agrega un correo en el campo de texto y 
						presiona la tecla <strong>Enter</strong>.
						<br/>
						Una vez que hayas agregado todos los correos, presiona el botón <strong>Agregar</strong>.
						<br/>
						<span style={{ color: 'gray' }}>

							{hintText}
						</span>
						<br/>
					</span>
					<EmailInput emails={emails} setEmails={setEmails} />

					<Button secondary widthFull onClick={requestReport} disabled={loading}>
						Solicitar reporte
					</Button>
				</div>
			</ModalContent>
		</Modal>
	);
};

export default ReportModal;