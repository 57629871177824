import request from '../../utils/request';

/**
 * fetch a list of all workshops
 * @returns {any}
 */
function fetchWorkshops() {
  return request({
    url: '/workshop/list',
    method: 'GET',
    private: true,
  });
}

function updateStatusWorkshop(id, status) {
  return request({
    url: `/workshop/${id}`,
    method: 'PATCH',
    private: true,
    data: { status },
  });
}

function updateWorkshop(id, updateData) {
  return request({
    url: `/workshop/update/${id}`,
    method: 'PUT',
    private: true,
    data: updateData,
  });
}

function fetchWorkshopReport(id) {
  return request({
    url: `/workshop/${id}/report`,
    method: 'GET',
    private: true,
  });
}

function fetchWorkShop(id) {
  return request({
    url: `/workshop/${id}`,
    method: 'GET',
    private: true,
  });
}

function createWorkShop(data) {
  return request({
    url: `/workshop`,
    method: 'POST',
    private: true,
    data: data,
  });
}
function deleteWorkshop(id) {
  return request({
    url: `/workshop/${id}`,
    method: 'DELETE',
    private: true,
  });
}

function fetchWorkshopsByAnnouncement(id) {
  return request({
    url: `/workshops/announcement/${id}`,
    method: 'GET',
    private: true,
  });
}
function associateWorkshopsWithAnnouncement(announcementId, workshopsIds) {
  return request({
    url: `workshop/${announcementId}`,
    method: 'POST',
    private: true,
    data: {
      announcementId: announcementId,
      workshops: workshopsIds,
    },
  });
}

function requestAnnouncementReport(id, payload) {
  return request({
    url: `workshop/announcement/${id}/report`,
    method: 'POST',
    data: payload,

  });
}

const workshopApi = {
  fetchWorkshops,
  fetchWorkshopsByAnnouncement,
  updateStatusWorkshop,
  updateWorkshop,
  fetchWorkshopReport,
  deleteWorkshop,
  fetchWorkShop,
  createWorkShop,
  associateWorkshopsWithAnnouncement,
  requestAnnouncementReport,
};

export default workshopApi;