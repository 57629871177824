import { CircularProgress, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';
import workshopApi from '../../WorkShopContainer/workshop-api';
import Alert from '../../../components/common/Alert';

const WorkshopsModal = ({ open, onClose, announcement, handleOpen }) => {
  const [loading, setLoading] = useState(true);
  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshopIds, setSelectedWorkshopIds] = useState([]);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open && announcement) {
      (async () => {
        try {
            const { data: allWorkshops } = await workshopApi.fetchWorkshops();
            const { data: announcementWorkshops } =
            await workshopApi.fetchWorkshopsByAnnouncement(announcement);

            const checkedWorkshops = allWorkshops.map((c) => {
            return announcementWorkshops.find((aw) => c.id === aw.workshopId.id)
              ? { ...c, checked: true }
              : { ...c, checked: false };
            });
            
            checkedWorkshops.sort((a, b) => a.title.localeCompare(b.title));
            setWorkshops(checkedWorkshops);
        } catch (error) {
          console.error(error);
          setMessage({ type: 'error', message: 'Error al cargar los talleres.' });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open, announcement]);

  const associateWorkshop = async () => {
    if (selectedWorkshopIds.length === 0) {
      return;
    }
    try {
      await workshopApi.associateWorkshopsWithAnnouncement(
        announcement,
        selectedWorkshopIds
      );

      setMessage('Talleres guardados exitosamente.');
    } catch (error) {
      setMessage('Hubo un error al guardar los tallares.');
    }
  };

  const onClickWorkshop = (clickedWorkshop) => {
    const updatedWorkshops = workshops.map((workshop) => {
      if (workshop.id === clickedWorkshop.id) {
        return { ...workshop, checked: !workshop.checked };
      }
      return workshop;
    });

    const selectedIds = updatedWorkshops
      .filter((workshop) => workshop.checked)
      .map((workshop) => workshop.id);

    setSelectedWorkshopIds(selectedIds);

    setWorkshops(updatedWorkshops);
  };

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        setMessage(null);
        onClose();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [message]);

  return (
    <>
      <Modal open={open} onClose={onClose} className="modal">
        <ModalContent title="Editar talleres" onClick={onClose}>
          {message && (
            <div className="message-container">
              <Alert type={message.includes('error') ? 'error' : 'success'}>
                {message}
              </Alert>
            </div>
          )}
          <div className="box">
            <div className="box__header">
              <span onClick={handleOpen}>Agregar talleres</span>
            </div>
            <hr />
            <span>
              Selecciona los talleres correspondientes a la convocatoria
            </span>
            <div className="box__container">
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 200,
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              ) : workshops.length > 0 ? (
                workshops.map((workshop) => (
                  <label key={workshop.id}>
                    <input
                      type="checkbox"
                      name="taller"
                      value={workshop.id}
                      checked={workshop.checked}
                      onChange={() => onClickWorkshop(workshop)}
                    />
                    <span>{workshop.title}</span>
                  </label>
                ))
              ) : (
                <div>No hay talleres disponibles</div>
              )}
            </div>
          </div>
          <div className="modal__action-container">
            <Button secondary onClick={onClose}>
              Volver
            </Button>
            <Button
              onClick={associateWorkshop}
              disabled={selectedWorkshopIds.length === 0}
            >
              Guardar
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WorkshopsModal;
